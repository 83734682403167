:root {
  --form-color: rgba(3, 218, 198, 0.1);
  --press-text: rgba(255, 231, 231, 0.87);
  --location-color: rgba(3, 218, 198, 0.1);
  --underline--investor: rgba(33, 33, 33, 0.38);
  --form-input: #fff;
  --form-color: #102624;
  --insight--one: #102624;
  --header-bg: #102624;
  --dark-txt: rgba(230, 225, 227, 0.6);
  --btn-white: #fff;
  --btn-black: #212121;
  --box-text: #fff;
  --footer-btn-text: rgba(33, 33, 33, 0.6);
  --footer-btn-hover: rgba(255, 255, 255, 1);
  --underline: rgba(3, 218, 198, 0.38);
  --platform-underline: rgba(3, 218, 198, 0.38);
  --box-bg: rgba(3, 218, 198, 0.1);
  --background: #03dac6;
  --description: rgba(255, 255, 255, 0.87);
  --header-btncolor: #03dac6;
  --btncolor: #03dac6;
  --bgcolor: #102624;
  --blackcolor: rgba(255, 255, 255, 0.87);
  --white: #121212;
  --black: #fff;
  --hover: #01645a;
  --img: #03dac6;
  --card-description: rgba(255, 255, 255, 0.6);
  --form-label: rgba(255, 255, 255, 0.38);
  --privacy-policy: rgba(255, 255, 255, 0.6);
  --footer-underline: #212121;
  --sign-text: rgba(255, 255, 255, 0.6);
  --sign-sub-text: rgba(255, 255, 255, 0.38);
  --insight-p-text: rgba(255, 255, 255, 0.6);
  --sidebar: #27333a;
  --table-header: #27333a;
  --table-body: #212121;
  --image-background: rgba(255, 255, 255, 0.80);
}

[dark-theme="dark"] {
  --image-background: rgba(133, 119, 119, 0.8);
  --table-body: rgba(230, 225, 227, 0.6);
  --sidebar: #144e4a;
  --table-header: #e5f3f2;

  --insight-p-text: #212121;
  --sign-text: rgba(33, 33, 33, 0.6);
  --sign-sub-text: rgba(33, 33, 33, 0.6);
  --privacy-policy: #212121;
  --form-label: #212121;
  --footer-underline: rgba(255, 255, 255, 0.38);
  --blue: #007bff;
  --indigo: #6610f2;
  /* --header-bg: rgba(1, 114, 104, 0.1); */
  --purple: #6f42c1;
  --background: #017268;
  --btncolor: #017268;
  --header-btncolor: #017268;
  --blackcolor: #212121;
  --bgcolor: #e5f0ef;
  --header-bg: #e5fdef;
  --white: #fff;
  --black: #000;
  --hover: #01645a;
  --img: #03dac6;
  --form-color: rgba(1, 114, 104, 0.1);
  --press-text: rgba(33, 33, 33, 0.87);
  --description: rgba(33, 33, 33, 0.68);
  --underline: rgba(1, 142, 130, 0.38);
  --platform-underline: rgba(1, 114, 104, 0.38);
  --box-bg: rgba(1, 114, 104, 0.1);
  --box-text: rgba(33, 33, 33, 0.88);
  --footer-btn-text: rgba(255, 255, 255, 0.6);
  --card-description: rgba(33, 33, 33, 0.6);
  --footer-btn-hover: rgba(255, 255, 255, 1);
  --btn-white: #fff;
  --btn-black: #212121;
  --dark-txt: rgba(33, 33, 33, 0.87);
  --underline--investor: rgba(255, 255, 255, 0.38);
  --insight--one: #fff;
  --form-color: #e6f1f0;
  --form-input: rgba(33, 33, 33, 0.38);
  --location-color: #fff;
}
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600&display=swap");

::selection {
  background-color: var(--background);
  color: white;
}
* {
  margin: 0px;
  padding: 0px;
  font-family: "Catamaran";
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease, box-shadow 0.3s ease;
  /* scrollbar-width: thin; */

  /* font-weight: 400; 
  */
}
html {
  background-color: var(--white);
  scroll-behavior: smooth;
}
body {
  /* background-color: #fff; */
  background-color: var(--white) !important;
  overflow-x: hidden;
}











/* Add transition specifically for elements that use CSS variables */
body,
.MuiPaper-root,
.custom-scrollbar,
button,
input,
label,
p,
span,
div {
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease, box-shadow 0.3s ease;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--btncolor);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable {
  scrollbar-width: thin;
  scrollbar-color: var(--btncolor) transparent;
}

.custom-button:hover {
  color: inherit; /* Prevent color change on hover */
}

/* Prevent hover color change */
.custom-button:hover {
  color: #fff; /* Maintain inherited color on hover */
  background-color: transparent !important; /* Optional: prevent background color change */
  border: none !important; /* Optional: remove border on hover */
}
.custom-button:active {
  color: #fff; /* Maintain inherited color on hover */
}

/* Popover Styling */
.popover {
  position: absolute;
  top: 55px;
  left: 0;
  background-color: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* padding: 10px; */
  border-radius: 10px;
  z-index: 100;
  width: 10rem;
  border-bottom: 1px solid var(--white);
}

.popover ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin: 20px;

  /* width:rem; */
}

.popover a {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}
.popover span {
  font-weight: 800;
  font-size: 16px;
}
.popover li {
  margin: 10px 0;
  /* border-bottom: 1px solid #ddd; Add bottom border */
}

.popover li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.popover li a {
  text-decoration: none;

  color: var(--black);
  font-family: Catamaran;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.popover li a:hover {
  color: var(--btncolor);
}

.nav button.active {
  /* background-color: black; */
  color: var(--blackcolor); /* Adjust as needed */
  font-weight: 600;
}
/* -- End -- */
/* common */

.page {
  /* margin-left: 6rem; */
  box-shadow: none !important;
}

.custom-managment-page {
  background: var(--white);
  box-shadow: none !important;
}
.green-page {
  /* margin-left: 6rem; */
  background-color: var(--background);
  box-shadow: none !important;
}
.container {
  display: flex;
  max-width: 1200px; /* Set your desired max width */
  margin: 0 auto; /* Center the container */
  padding: 0 20px; /* Add some padding for smaller screens */
}

.underline {
  border-bottom: 5px solid var(--underline);
  width: 140px;
  height: 4px;
  margin-top: 48px;
  border-radius: 10px;
}
@media (max-width: 480px) {
  .underline {
    border-bottom: 5px solid var(--underline);
    width: 140px;
    height: 4px;
    margin-top: 15px;
    border-radius: 10px;
  }
}
.underline--large {
  /* height: 4px !important; Only overriding height */
  margin-top: 15px;
  margin-bottom: 50px;
}

.platform {
  color: var(--white);
  text-align: center;
  font-family: Catamaran;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px; /* 100% */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Title styling */

.platform h1 {
  font-weight: 800;
}

/* Underline styling */
.platform span {
  border-bottom: 5px solid var(--underline--investor);
  width: 140px;
  /* padding-top: 20px; */
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  .platform {
    font-size: 40px; /* Reduce font size for tablets */
    line-height: 40px; /* Adjust line height accordingly */
  }

  .platform span {
    width: 120px; /* Reduce underline width */
    padding-top: 15px; /* Reduce padding for better fit */
  }
}

/* Small screens (landscape phones, 768px and below) */
@media (max-width: 768px) {
  .platform {
    font-size: 35px; /* Reduce font size further for smaller screens */
    line-height: 36px; /* Adjust line height */
  }

  .platform span {
    width: 100px; /* Reduce underline width for smaller screens */
    padding-top: 12px; /* Adjust padding */
  }
}

/* Extra small screens (portrait phones, 480px and below) */
@media (max-width: 480px) {
  .platform {
    font-size: 28px; /* Further reduce font size */
    line-height: 32px; /* Adjust line height */
    padding: 0 10px; /* Add some padding for better spacing */
  }

  .platform span {
    width: 80px; /* Make the underline smaller */
    padding-top: 8px; /* Reduce padding for the underline */
  }
}



.platform-wh {
  color: var(--blackcolor);
  text-align: center;
  font-family: Catamaran;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.platform-wh span {
  border-bottom: 5px solid rgba(1, 142, 130, 0.38);
  width: 140px;
  /* padding-top: 20px; */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .platform-wh {
    font-size: 40px; /* Decrease font size for tablets */
    line-height: 45px;
  }
  .platform-wh span {
    width: 120px; /* Adjust span width */
    border-bottom-width: 4px; /* Decrease border thickness */
  }
}

@media (max-width: 768px) {
  .platform-wh {
    font-size: 32px; /* Further reduce font size for smaller screens */
    line-height: 40px;
  }
  .platform-wh span {
    width: 100px; /* Adjust width on smaller screens */
    border-bottom-width: 3px;
  }
}

@media (max-width: 480px) {
  .platform-wh {
    font-size: 28px; /* Smallest font size for narrow screens */
    line-height: 35px;
  }
  .platform-wh span {
    width: 80px; /* Minimum width for mobile */
    border-bottom-width: 2px;
  }
}

.position {
  font-size: 18px;
  color: var(--white);
  margin-top: 5px;
}

.intainAdmin {
  background: var(--white);
}


.darkmode-label {
  width: 40px; /* Reduced width */
  height: 20px; /* Reduced height */
  position: relative;
  display: block;
  background-color: #03dac6;
  border-radius: 10px; /* Adjusted for smaller size */
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.4),
    inset 0px -1px 3px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
}

.darkmode-label::after {
  content: "";
  width: 16px; /* Reduced size */
  height: 16px; /* Reduced size */
  position: absolute;
  top: 2px; /* Adjusted for smaller size */
  left: 2px; /* Adjusted for smaller size */
  background: linear-gradient(180deg, #ffffff, #ffffff);
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

#darkmode-toggle {
  width: 0;
  height: 0;
  visibility: hidden;
}

#darkmode-toggle:checked + .darkmode-label {
  background-color: #017268;
}

#darkmode-toggle:checked + .darkmode-label::after {
  left: 90%; /* Adjusted for smaller size */
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #212121);
}

/* Adjusted size for sun and moon icons */
.sun,
.moon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  transition: 0.3s;
  z-index: 100;
}

.moon {
  left: 2px; /* Adjusted position */
  opacity: 1; /* Moon is visible when unchecked */
}

.sun {
  right: 4px; /* Adjusted position */
  opacity: 0; /* Sun is hidden when unchecked */
}

#darkmode-toggle:checked + .darkmode-label .moon {
  opacity: 0; /* Moon hides when checked */
}

#darkmode-toggle:checked + .darkmode-label .sun {
  opacity: 1; /* Sun shows when checked */
}

.darkmode-label:active::after {
  width: 13px;
  height: 13px;
}







.asterisk {
  color: var(--background);
  position: relative;
  bottom: 2px;
}
.login-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  margin-top: 5px;
}
.input-flex {
  position: relative;
  display: flex;
  align-items: center;
}
.input-eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: var(--background);
}

.hr-line {
  flex: 1;
  border: 1px solid rgba(33, 33, 33, 0.38);
}
.span-line {
  margin: 0 10px;
  color: var(--blackcolor);
  font-size: 28px;
  position: relative;
  bottom: 2px;
}
.custom-dialog .MuiPaper-root {
  background-color: var(--form-color); /* Set dialog box color */
  color: var(--black); /* Optional: Set text color to white for contrast */
}

.custom-dialog .MuiDialogContent-root {
  color: var(--black); /* Optional: Set text color to white for contrast */
}

.custom-dialog .MuiDialogActions-root {
  color: var(--black); /* Optional: Set text color to white for contrast */
}

.custom-dialog .MuiIconButton-root {
  color: var(--black); /* Optional: Set text color to white for contrast */
}





.underlined {
  text-decoration: underline;
  text-decoration-color: var(--background);
  text-decoration-thickness: 4px;
  text-underline-offset: 2px;
}



.custom-managment-form {
  background-color: var(--bgcolor);
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 80px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 45%; /* Increased width */
  height: 43%; /* Increased height */
  /* padding: 30px; Optional: Increase padding for more content space */
}

.popup p {
  font-size: 36px;
  margin-bottom: 20px;
  font-family: Mulish;
  font-weight: 800;
}
.popup span {
  color: var(--hover);
}

.popup button {
  position: relative;
  left: 17%;
  background-color: #fff;
  /* background-color: var(--btn-white);
  color: var(--white); */
  padding: 10px;
  /* border: ; */
  cursor: pointer;
  border-radius: 5px;
  width: 15%;
  font-size: 18px;
  font-family: Mulish;
  font-weight: 600;
  top: 10px;
}

.checkIcon {
  color: var(--hover); /* Change to your desired color */
}



.input-sign-up-eye-icon {
  position: relative;
  bottom: 2em;
  right: -25em;
  cursor: pointer;
  font-size: 18px;
  color: var(--background);
}

@media (max-width: 1024px) {
  .input-sign-up-eye-icon {
    right: -24em; /* Adjust position for 1024px screens */
    font-size: 16px; /* Slightly smaller font size */
  }
}

@media (max-width: 768px) {
  .input-sign-up-eye-icon {
    right: -37em; /* Adjust position for 768px screens */
    font-size: 14px; /* Further reduce font size */
  }
}

@media (max-width: 480px) {
  .input-sign-up-eye-icon {
    right: -26em; /* Adjust position for 480px screens */
    font-size: 12px; /* Smallest font size for mobile screens */
  }
}

.input-sign-up-new-eye-icon {
  /* position: absolute; */
  /* top: 64%; */
  /* right: 60px; */
  /* bottom: 41.5%; */
  cursor: pointer;
  font-size: 18px;
  color: var(--background);
}

.message-box {
  max-width: 575px;
  margin: auto;
  font-size: 16px;
}

.forgot-password h2,
.forgot-password p {
  color: var(--black);
}
.forgot-password button {
  width: 20%;
  position: relative;
  left: 70%;
  padding: 10px;
}


.insight-header span,
.insight-header h3 {
  color: var(--black);
}

.add-btn {
  background-color: var(--background) !important;
  color: var(--white) !important;
}
.loading-message {
  font-size: 1.2rem; /* Adjust font size as needed */
  color: #888; /* Set your preferred color */
  text-align: center; /* Center align the text */
  margin: 20px auto; /* Center it vertically and horizontally within the container */
  margin-right: 1.2%;
}
.typography-color {
  color: var(--black) !important;
}

/* Override default scrollbar only for custom-scrollbar class */
.custom-scrollbar {
  scrollbar-width: auto !important; /* Override the global thin setting */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 7px !important;
  height: 4px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--bgcolor) !important;
  border-radius: 2px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--sidebar) !important;
  border-radius: 2px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #028f84 !important;
}

.header-text {
  color: var(--white);
  text-align: center;
  font-family: Catamaran;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px; /* 100% */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-text span {
  border-bottom: 5px solid var(--underline--investor);
  width: 140px;
  /* padding-top: 20px; */
}

/* Mobile devices (portrait and landscape) */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .header-text {
    font-size: 24px;
    line-height: 32px;
    -webkit-text-size-adjust: 100%;
  }

  .header-text span {
    width: 90px;
  }
}

/* iPads and Tablets (portrait and landscape) */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .header-text {
    font-size: 28px;
    line-height: 40px;
    -webkit-text-size-adjust: 100%;
  }

  .header-text span {
    width: 110px;
  }
}

/* Large screens and laptops */
@media only screen and (min-device-width: 1025px) {
  .header-text {
    font-size: 36px;
    line-height: 50px;
  }

  .header-text span {
    width: 140px;
  }
}

/* For retina and high DPI displays */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .header-text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* //dark mode header text */

.header-dark-text {
  color: var(--black);
  text-align: center;
  font-family: Catamaran;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px; /* 100% */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-dark-text span {
  border-bottom: 5px solid rgba(1, 142, 130, 0.38);
  width: 140px;
  /* padding-top: 20px; */
}

/* Mobile devices (portrait and landscape) */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .header-dark-text {
    font-size: 24px;
    line-height: 32px;
    -webkit-text-size-adjust: 100%;
  }

  .header-dark-text span {
    width: 90px;
  }
}

/* iPads and Tablets (portrait and landscape) */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .header-dark-text {
    font-size: 28px;
    line-height: 40px;
    -webkit-text-size-adjust: 100%;
  }

  .header-dark-text span {
    width: 110px;
  }
}

/* Large screens and laptops */
@media only screen and (min-device-width: 1025px) {
  .header-dark-text {
    font-size: 36px;
    line-height: 50px;
  }

  .header-dark-text span {
    width: 140px;
  }
}

/* For retina and high DPI displays */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .header-dark-text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.header-text {
  color: var(--white);
  text-align: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  
  /* Fluid typography */
  font-size: clamp(1.5rem, 2vw + 1rem, 2.25rem); /* 24px to 36px */
  line-height: clamp(2rem, 2.5vw + 1.5rem, 3.125rem); /* 32px to 50px */
  
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  /* High DPI optimizations */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.header-text span {
  border-bottom: 5px solid var(--underline--investor);
  width: 140px;
  /* padding-top: 20px; */
}

/* Mobile devices (portrait and landscape) */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) {
  .header-text {
    font-size: 24px;
    line-height: 32px;
    -webkit-text-size-adjust: 100%;
  }
  
  .header-text span {
    width: 90px;
  }
}

/* iPads and Tablets (portrait and landscape) */
@media only screen 
  and (min-device-width: 481px) 
  and (max-device-width: 1024px) {
  .header-text {
    font-size: 28px;
    line-height: 40px;
    -webkit-text-size-adjust: 100%;
  }
  
  .header-text span {
    width: 110px;
  }
}

/* Large screens and laptops */
@media only screen 
  and (min-device-width: 1025px) {
  .header-text {
    font-size: 36px;
    line-height: 50px;
  }
  
  .header-text span {
    width: 140px;
  }
}

/* For retina and high DPI displays */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  .header-text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}



  /* Close icon container */
  .closeicon {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    position: sticky;
    top: 0;
    background: var(--bgcolor);
    z-index: 1;
  }
  
  /* Close icon button */
  .closeicon .icon {
    cursor: pointer;
    color: var(--blackcolor);
    font-size: 24px;
    /* padding: 4px; */
    transition: transform 0.2s ease;
  }
  
  .closeicon .icon:hover {
    transform: scale(1.1);
  }
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .closeicon {
      padding: 12px;
    }
    
    .closeicon .icon {
      font-size: 20px;
    }
  }

  .homepage-container {
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
  }



